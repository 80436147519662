<template>
	<div class="download">
		<div class="banner">
			<img src="@/assets/supplier/banner.png" alt="" class="HANDandPRODUCTS">
			<div class="logo">
				<img src="@/assets/supplier/logoCN.png" alt="" v-if="lang=='zh'">
				<img src="@/assets/supplier/logoEN.png" alt="" v-else>
			</div>
		</div>
		<div class="one">
			{{i18n('332')}}
		</div>
		<div class="two">
			{{i18n('333')}}
		</div>
		<div class="three">
			<div class="left">
				<img src="@/assets/supplier/FreshIngredients.png" alt="">
			</div>
			<div class="right">
				<div class="title">
					{{i18n('334')}}
				</div>
				<div class="dis">
					{{i18n('335')}}
				</div>
				<div class="lxfs">
					<img src="@/assets/supplier/phone.png" alt="">{{i18n('336')}}
					<div class="">
						<div class="phone">
							{{i18n('47')}}：09701665555
						</div>
						<div class="phone wx">
							{{i18n('201')}}：doforjob
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="four">
			{{i18n('337')}}
		</div>
		<img src="@/assets/supplier/Process.png" alt="" style="display: block;margin: 0 auto;">
		<div class="five">
			<div class="tit">
				{{i18n('338')}}
			</div>
			<div class="list">
				<a href="https://www.lazada.com.ph/shop/yeah-fresh" target="_blank"><img src="@/assets/supplier/01.png" alt=""></a>
				<a href="https://shopee.ph/yeahfreshph" target="_blank"><img src="@/assets/supplier/02.png" alt=""></a>
				<a href="http://" target="_blank"><img src="@/assets/supplier/03.png" alt=""></a>
				<a href="https://www.foodpanda.ph/shop/nvwf/yeah-fresh-makati" target="_blank"><img src="@/assets/supplier/04.png" alt=""></a>
				<a href="https://pickaroo.com/yeah-fresh-mini-mart/" target="_blank"><img src="@/assets/supplier/05.png" alt=""></a>
				<a href="https://www.metromart.com/shops/yeah-fresh-malate" target="_blank"><img src="@/assets/supplier/06.png" alt=""></a>
				<a href="https://www.tiktok.com/@yeahfreshph" target="_blank"><img src="@/assets/supplier/07.png" alt=""></a>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "download",
		components: {
			foot
		},
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		destroyed() {

		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped="scoped">
	.five{
		padding-top: 90px;
		padding-bottom: 60px;
		width: 1200px;
		margin: 0 auto;
	}
	.five .tit{
		font-size: 16px;
		text-align: center;
		padding-bottom: 30px;
		color: #4b9e49;
	}
	.five .list{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.five .list img{
		display: block;
	}
	.four{
		padding-bottom: 100px;
		text-align: center;
		width: 1000px;
		margin: 0 auto;
		font-size: 16px;
	}
	.three {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		padding-bottom: 130px;
	}

	.three .right {
		margin-left: -80px;
		width: 700px;
	}

	.three .right .title {
		font-size: 32px;
		text-align: center;
		padding-bottom: 20px;
		color: #4b9e49;
	}

	.three .right .dis {
		text-align: center;
		font-size: 16px;
		color: #106c0e;
		padding-bottom: 35px;
	}

	.three .right .lxfs {
		padding-left: 350px;
		display: flex;
	}

	.three .right .lxfs img {
		width: 30px;
		height: 30px;
		display: block;
		margin-right: 25px;
	}

	.three .right .lxfs .phone {
		font-size: 20px;
		color: #000;
		padding-bottom: 15px;
	}

	.two {
		width: 1050px;
		margin: 0 auto;
		text-align: center;
		padding-bottom: 130px;
		font-size: 18px;
	}

	.one {
		text-align: center;
		font-size: 16px;
		color: #106c0e;
		padding-bottom: 30px;
	}

	.banner {
		width: 1920px;
		height: 509px;
		margin-bottom: 50px;
		background: url(../assets/supplier/Tiles.jpg);
		overflow: hidden;
		padding-top: 70px;
		box-sizing: border-box;
		position: relative;
	}

	.banner .logo {
		position: absolute;
		left: 0;
		top: 55px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.banner .logo img {
		display: block;
	}

	.HANDandPRODUCTS {
		width: 1340px;
		margin: 0 auto;
		display: block;
	}
</style>
